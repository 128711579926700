<template>
    <section class="login">
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth">
                    <div class="row w-100 flex-grow">
                        <div class="col-xl-4 col-lg-6 mx-auto">
                            <div class="auth-form-light text-left p-5">
                                <div class="brand-logo text-center">
                                    <img src="../../assets/images/watch-logo.png" />
                                </div>
                                <form methods="POST" class="pt-3">
                                    <b-form-group id="input-group-1" label="Client ID" label-for="input-1">
                                        <b-form-input id="input-1" :value="clientId" type="text"
                                            placeholder="Enter Client ID" 
                                            aria-describedby="input-2-live-feedback"
                                            readonly></b-form-input>
                                    </b-form-group>
                                    <!-- <b-form-group id="input-group-2" label="Email Address" label-for="input-2">
                                        <b-form-input id="input-2" v-model="email" type="email"
                                            placeholder="Email Address"
                                            aria-describedby="input-2-live-feedback"></b-form-input>
                                    </b-form-group>
                                    <b-form-group id="input-group-3" label="Password" label-for="input-3">
                                        <b-form-input id="input-3" v-model="password" type="password"
                                            placeholder="Password"
                                            aria-describedby="input-3-live-feedback"></b-form-input>
                                    </b-form-group> -->
                                    <b-form-group id="input-group-1" label="Email Address" label-for="input-1">
                                        <b-form-input id="input-1" type="email" placeholder="Email Address"
                                            v-model="$v.email.$model" :state="$v.email.$dirty ? !$v.email.$error : null"
                                            aria-describedby="input-2-live-feedback"></b-form-input>
                                        <b-form-invalid-feedback v-if="!$v.email.required" id="input-2-live-feedback">
                                            {{ $t("field-required") }}
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback v-if="!$v.email.email" id="input-2-live-feedback">
                                            Invalid email format
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    <b-form-group id="input-group-2" label="Password" label-for="input-2">
                                        <span class="icon is-small is-right">
                                            <b-form-input id="input-2" :type="inputType" placeholder="Password"
                                                v-model="$v.password.$model"
                                                :state="$v.password.$dirty ? !$v.password.$error : null"
                                                aria-describedby="input-3-live-feedback"></b-form-input>
                                            <b-form-invalid-feedback v-if="!$v.password.required"
                                                id="input-3-live-feedback">
                                                {{ $t("field-required") }}
                                            </b-form-invalid-feedback>
                                            <b-form-invalid-feedback v-if="!$v.password.minLength"
                                                id="input-3-live-feedback">
                                                Password must have at least {{ $v.password.$params.minLength.min }}
                                                letters.
                                            </b-form-invalid-feedback>
                                            <i @click="toggleShow" class="fas password-icon"
                                                :class="{ 'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword }"></i>
                                        </span>
                                    </b-form-group>
                                    <div class="my-2 d-flex justify-content-between align-items-center">
                                        <!-- Checkbox and Terms & Conditions -->
                                    </div>
                                    <div class="mt-3">
                                        <button type="button" class="btn btn-dark btn-lg btn-block"
                                            :disabled="formInvalid" @click="deleteMobileSpotters()">
                                            Remove Account
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>



            <Footer />

            <!-- page-body-wrapper ends -->

        </div>
    </section>
</template>


<script>
import router from "../../router"
import API from '@/api'
import alertError from "../../components/alerts/sweet-alert/alertError";
import Footer from "../../layout/partials/Footer";
import { validationMixin } from "vuelidate";
import { helpers, required, minLength, email, sameAs } from "vuelidate/lib/validators";

export default {
    name: "SpotterDelete",
    mixins: [validationMixin],
    components: {
        Footer
    },
    data() {
        return {
            email: '',
            password: '',
            clientId: '',
            showPassword: false,
            inputType: 'password'
        };
    }, validations: {
        email: {
                email,
                required,
            }, password: {
                required,
                minLength: minLength(6),
            }
    },

    methods: {
        checkFunction(event) {
            if (event.target.checked) {
                this.isChecked = true;
            } else {
                this.isChecked = false;
            }

        },
        
        deleteMobileSpotters(e) {
            this.$swal({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    let data = {
                        email: this.email,
                        password: this.password,
                        client_id: this.clientId,
                    }
                    API.deleteMobileSpotter(
                        data,
                        response => {
                            if (response.status === 201) {
                                this.$swal(
                                    'Deleted!',
                                    response.message,
                                    'success'
                                )
                                this.email = '';
                                this.password = '';
                            } else {
                                this.$swal(
                                    'Error!',
                                    response.message,
                                    'error'
                                )
                            }
                        },
                        err => {
                            this.$swal(
                                'Error!',
                                'An error occurred while deleting the record.',
                                'error'
                            )
                        }
                    )
                }
            })
        },
        toggleShow() {
            this.showPassword = !this.showPassword; 
            this.inputType = this.showPassword ? 'text' : 'password'; 
        }
    },
    created() {
        this.clientId = this.$route.params.client_id;
    },
}
</script>
